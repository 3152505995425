import { useQuery } from "@tanstack/react-query"
import { api } from "../services/api"
import { IPost, IPosts } from "../dtos/IPosts.dto"
import { IQuery, IQueryPost } from "../dtos/IQuery.dto"

export const usePostsByThemeId = (themeId: string, query?: IQuery) => {
  const page = query?.page || 1
  const limit = query?.per_page || 10
  return useQuery(["posts", themeId, page], async () => {
    const { data } = await api.get<IPosts>(
      `/wp/v2/blog?tema_das_postagens=${themeId}&page=${page}&per_page=${limit}`,
    )
    return data
  })
}

export const usePosts = (query?: IQuery) => {
  const page = query?.page || 1
  const limit = query?.per_page || 10
  return useQuery(["posts", page], async () => {
    const { data } = await api.get<IPosts>(
      `/wp/v2/blog?page=${page}&per_page=${limit}`,
    )
    return data
  })
}

export const usePostById = (query?: IQueryPost) => {
  const id = query?.id
  return useQuery(["post", id], async () => {
    const { data } = await api.get<IPost>(`/wp/v2/blog/${id}`)
    return data
  })
}

export const usePostsOrder = (query?: IQuery) => {
  const orderby = query?.orderby || "date"
  const order = query?.order || "desc"
  const page = query?.page || 1
  const limit = query?.per_page || 10
  return useQuery(["posts", orderby, order, page, limit], async () => {
    const { data } = await api.get<IPosts>(
      `/wp/v2/blog/?orderby=${orderby}&order=${order}&page=${page}&per_page=${limit}`,
    )
    return data
  })
}
